redoRollingBarWidth();

window.setInterval(redoRollingBarWidth,1000)

function redoRollingBarWidth(){
    const scrollingBars = document.querySelectorAll('.is-style-scrolling.act-logos-partners')
    if(scrollingBars){
        scrollingBars.forEach(function(scroller){
            let spacer = scroller.getElementsByClassName('act-logos-partners__spacer');
            let bars = scroller.getElementsByClassName('act-logos-partners__bar');
            let w=0;
            if(spacer){
                for(let el of spacer){
                    let images = el.getElementsByClassName('act-logos-partners__logo');
                    if(images){
                        for(let img of images){
                            //console.log(img.clientWidth);
                            w+=img.clientWidth;
                        }
                    }
                }
            }
            if(bars){
                for(let el of bars){
                    el.setAttribute('style','width:'+w+'px');
                }
            }
        })
    }
}