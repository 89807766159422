import { tns } from 'tiny-slider'
const $logosPartnersSliders = document.querySelectorAll('.act-logos-partners__slider');

(function ($) {
    $(document).ready(function () {
        $logosPartnersSliders.forEach(function ($gallery) {
            var $parent = $gallery.closest('.tns-outer')
            var $id = $gallery.getAttribute('id');
            var slider = tns({
                container: '#'+$id,
                mode:'gallery',
                items: 1,
                loop: true,
                center: false,
                mouseDrag: true,
                slideBy: 1,
                //swipeAngle: false,
                speed: 400,
                autoplay: true,
                autoplayButton: false,
                controls:false,
                nav:false,
                //controlsText: ['<svg class="icon icon-slide_prev"><use xlink:href="#icon-slide_prev"></use></svg>', '<svg class="icon icon-slide_next"><use xlink:href="#icon-slide_next"></use></svg>'],
                //controlsPosition:"bottom",
                //navPosition:"bottom",
                /*onInit(info) {
                    info.controlsContainer.setAttribute('tabindex', -1);
                    info.nextButton.setAttribute('tabindex', 0);
                    info.prevButton.setAttribute('tabindex', 0);
                }*/
            });
        });

    });
})(jQuery);
